/** Este arquivos existem para evitar um preload de página do gatsby, servem como paginas intermediarias */
import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  justify-content: center;
  padding: 5rem 0;
`;

export default function UpdatePage() {
  return <Div>Página em atualização</Div>;
}
